import { Injectable } from '@angular/core';
import { LoaderPortalServicesService } from '../services/loader-portal-services.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  loggedIn = false;
  isAuthenticated = false;
  isAuthorized = false
  activeUser: string | undefined = "unknown user";
  constructor(private router: Router, private service: LoaderPortalServicesService) { 



  }



  // isLoggedIn() {

  //   return this.authService.instance.getActiveAccount() != null

  // }

  // logout(): void {
  //   this.authService.logoutRedirect(

  //     { account: this.authService.instance.getActiveAccount() }
  //   );
  // }

  // login() {
  //   this.authService.loginRedirect({
  //     scopes: ["user.read"]
  //   });
  // }


  // checkAccess() {
  //   return true
  //   const item = localStorage.getItem('user_permission');
  //   if (item != null) {
  //     return JSON.parse(item)['has_access']
  //   }
  //   else {
  //     this.getAccess().then(result => {
  //       localStorage.setItem("user_permission", result)
  //       const item = localStorage.getItem('user_permission');
  //       if (item != null) {
  //         return JSON.parse(item)['has_access']
  //       }

  //     })
  //   }
  //   return false
  // }


  // async getAccess(): Promise<string> {
  //   try {
  //     const result = await this.service.check_user_access().toPromise();
  //     return JSON.stringify(result);
  //   } catch (error) {
  //     console.error('Failed to check access:', error);
  //     return JSON.stringify({ "has_access": false });
  //   }
  // }


  // setAuthenticationStatus(): void {
  //   let activeAccount = this.authService.instance.getActiveAccount();

  //   if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
  //     activeAccount = this.authService.instance.getAllAccounts()[0];
  //     this.authService.instance.setActiveAccount(activeAccount);
  //   }

  //   this.isAuthenticated = activeAccount ? true : false;
  //   this.activeUser = activeAccount?.username;
  // }

}


